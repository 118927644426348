import { gql } from "@apollo/client";

export type ReportPropType = {
    ID: number;
    Name: string;
    Description: string;
    ShowLink: boolean;
    RestrictToOrganisation: boolean;
    DisableExportToExcel: boolean;
    OrganisationIDs: number[];
    LinkPage: string;
    PKField: string;
    LinkText: string;
    CustomReportPage: string;
    NotRunOnLoad: boolean;
    ReportSQL: string;
    DefaultSort: string;
    GroupBy: string;
    ReportGroupID: number;
    PermissionID: number;
    ReportGroup: {
        Value?: {
            Description: string;
        };
    };
    ReportColumnIDs: number[];
    ApplicationAreaIDs: number[];
    AdminUserOnly: boolean;
    ReportColumns: {
        ID: number;
        Description: string;
        SQLColumnName: string;
        ListOrder: number;
        DataType: number;
        LinkURL: string;
        LinkField: string;
        TotalColumn: number;
        Bold: boolean;
        Italic: boolean;
        RAG: boolean;
        CurrencySymbol: string;
        RedStart: number;
        RedStop: number;
        AmberStart: number;
        AmberStop: number;
        GreenStart: number;
        GreenStop: number;
        ToolTip: string;
    }[];
    ReportParameterIDs: number[];
    ReportParameters: {
        ID: number;
        Description: string;
        SQLParameterName: string;
        DataType: number;
        HelpText: number;
        MaxLength: number;
        AppendSQL: string;
        WildcardMode: number;
        Required: boolean;
        MultiSelect: boolean;
        DateInclusive: boolean;
        IncludeParameterAsNull: boolean;
        ListOrder: number;
    }[];
    ReportKPIs: {
      ID: number;
      Title: string;
      Tooltip: string;
      Icon: string;
      SQLQuery: string;
      LinkUrl: string;
      Symbol: string;
      Prefix: string;
      Suffix: string;
      DashboardDataTypeID: number;
      CssFormatting: string;
      ListOrder: number;
    }[]
}

export const ReportsGridQuery = gql`
query(
    $skip: Int,
    $take: Int,
    $order: [ReportSortInput!],
    $where: ReportFilterInput
  ) {
    grid: Reports(
        skip: $skip,
        take: $take,
        order: $order,
        where: $where
    ) {
        totalCount
        items {
            ID
            Name
            Description
            ShowLink
            RestrictToOrganisation
            LinkPage
            PKField
            LinkText
            ReportGroupID
            ReportGroup {
                Value {
                    Description
                }
            }
        }
    }
}`;

export const ReportScreenQuery = gql`
query(
    $id: Int!
  ) {
    screen: Report(
      iD: $id
    ) {
        ID
        Name
        Description
        ShowLink
        RestrictToOrganisation
        DisableExportToExcel
        OrganisationIDs
        LinkPage
        PKField
        LinkText
        CustomReportPage
        NotRunOnLoad
        ReportGroupID
        ReportSQL
        DefaultSort
        GroupBy
        ReportGroupID
        PermissionID
        ReportGroup {
            Value {
                Description
            }
        }
        ApplicationAreaIDs
        ReportColumnIDs
        AdminUserOnly
        ReportColumns {
            ID
            Description
            SQLColumnName
            ListOrder
            DataType
            LinkURL
            LinkField
            TotalColumn
            Bold
            Italic
            RAG
            CurrencySymbol
            RedStart
            RedStop
            AmberStart
            AmberStop
            GreenStart
            GreenStop
            ToolTip
        }
        ReportParameterIDs
        ReportParameters {
            ID
            Description
            SQLParameterName
            DataType
            HelpText
            MaxLength
            AppendSQL
            WildcardMode
            Required
            MultiSelect
            DateInclusive
            IncludeParameterAsNull
            ListOrder
        }
        ReportKPIs {
            ID
            Title
            Tooltip
            Icon
            SQLQuery
            LinkUrl
            Symbol
            Prefix
            Suffix
            DashboardDataTypeID
            CssFormatting
            ListOrder
        }
    }
}`

export const ReportGroupsDropdownQuery = gql`
  query {
    dropdown: ReportGroups(order: { Description: ASC }) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const ReportParameterTypesDropdownQuery = gql`
  query {
    dropdown: ReportParameterTypes(order: { ID: ASC }) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const ReportWildcardModesDropdownQuery = gql`
  query {
    dropdown: ReportWildcardModes {
      items {
        value: WildcardMode
        label: Description
      }
    }
  }
`;

export const ReportFormattingOptionsDropdownQuery = gql`
  query {
    dropdown: ReportFormattingOptions {
      items {
        value: DataType
        label: Description
      }
    }
  }
`;

export const ReportParameterValuesDropdownQuery = gql`
  query($reportParameterTypeID: Int!) {
    dropdown: ReportParameterValues(ReportParameterTypeID: $reportParameterTypeID, order: { Description: ASC }) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;
