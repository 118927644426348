import React, { useState, useEffect } from 'react'
import Form from 'react-bootstrap/Form'
import { useAuthAPI } from "@whitecobalt/tungsten/hooks/useAuthAPI";
import OneSignalReact from 'react-onesignal';
import ModalView from '@whitecobalt/tungsten/Modal/View'
import Image from '@whitecobalt/tungsten/esm/components/Image';

const isSupported = ('serviceWorker' in navigator)

const OneSignalSubscription: React.FunctionComponent = () => {
    const subscribe = useAuthAPI('/api/SaveUser/subscribe-notifications')
    const unsubscribe = useAuthAPI('/api/SaveUser/unsubscribe-notifications')
	const [isSubscribe, setIsSubscribe] = useState<boolean>(false);
    const [allow, setAllow] = useState<boolean>(false);

	useEffect(() => {
        if(isSupported) {
            OneSignalReact.getNotificationPermission(status => setAllow(status !== 'denied'))
            OneSignalReact.isPushNotificationsEnabled().then((enabled) => {
                setIsSubscribe(enabled)
            })
        }
	}, [])

	const handleToggler = async (event: any) => {
		const isEnabled = event.target.checked

		if (isEnabled) {
            OneSignalReact.showNativePrompt().then(() => {
                OneSignalReact.setSubscription(true).then(() => {
                    OneSignalReact.getUserId().then((oneSignalPlayerID) => {
                        return subscribe.call({
                            data: {
                                oneSignalPlayerID
                            }
                        })
                    }).then(() => {
                        setIsSubscribe(true)
                    })
                })
            })
            // OneSignalReact.setSubscription(true).then(() => {
            //     OneSignalReact.getUserId().then((oneSignalPlayerID) => {
            //         requestSaveOneSignalID.call({
            //             data: {
            //                 oneSignalPlayerID
            //             }
            //         })
            //     }).then(() => {
            //         setIsSubscribe(true)
            //     })
            // })
		} else {
            OneSignalReact.setSubscription(false).then(() => {
                return unsubscribe.call()
            }).then(() => {
                setIsSubscribe(false)
            })
		}
	}
    
    return isSupported ? (
        <Form.Group className="d-flex align-items-center" onClick={allow ? undefined : () => ModalView({
            title: 'Permission Denied',
            titleIcon: 'fas fa-exclamation-triangle',
            body: (
                <div>
                    <p className="text-danger">You are not allowing the browser notification feature on this website. To enable it, you must allow it first on your browser. see the image below</p>
                    <Image src="/media/other/permission.png" className="w-100" />
                </div>
            )
        })}>
			<Form.Check 
                type="switch"
                id="onesignal-switch"
                checked={isSubscribe}
                label="Enable Desktop Notifications"
                onChange={handleToggler}
                disabled={subscribe.loading || unsubscribe.loading || allow === false}/>
        </Form.Group>
    ) : null
}

export default OneSignalSubscription