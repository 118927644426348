import React, { useEffect, useState } from 'react'
import Button from '@whitecobalt/tungsten/Button'
import Icon from '@whitecobalt/tungsten/Icon'
import { SessionActionType, useSession } from '@whitecobalt/tungsten/esm/components/Session';
import { useAuthAPI } from '@whitecobalt/tungsten/esm/common/hooks/useAuthAPI';
import ModalForm from '@whitecobalt/tungsten/esm/components/Modal/Form';
import FormManager from '@whitecobalt/tungsten/esm/components/FormManager';
import PasswordField from '@whitecobalt/tungsten/esm/components/Fields/PasswordField';
import Toaster from '@whitecobalt/tungsten/esm/components/Toaster';
import branding from '@config/branding';
import { UserProps } from '@types';

const ChangeEmailButton: React.FunctionComponent = () => {
    const [{user}, dispatch] = useSession()
    const requestUser = useAuthAPI('/api/Me', { method: 'GET' })

    const [{currentEmail, requestedChangeEmail}, setStates] = useState({
        currentEmail: user?.emailAddress,
        requestedChangeEmail: false
    })

    useEffect(() => {
        if(requestedChangeEmail) {
            const cleanup = setInterval(() => {
                requestUser.call().then(async response => {
                    const payload: UserProps | null = response.data || null
                    if(currentEmail !== payload?.emailAddress) {
                        setStates(prev => ({...prev, 
                            currentEmail: payload?.emailAddress, 
                            requestedChangeEmail: false
                        }))
                        dispatch({ 
                            type: SessionActionType.SET_USER,
                            payload
                        })
                    }
                })
            }, 10000)

            return () => clearInterval(cleanup)
        }
    }, [requestedChangeEmail])

    const requestChangeEmailAddress = useAuthAPI("/api/ChangeEmailRegistration");

    const handleChangeEmailAddress = () => {
        const recursivePromptForm = () => {
            ModalForm({
                key: "email-address",
                title: "Change Email Address",
                titleIcon: "fa fa-pen fa-lg",
                submit: "Confirm",
                body: (
                    <>
                        <p>
                            Please enter your new email address below and press Confirm.<br/>
                            You will then receive an email asking you to confirm this address.<br/>
                            Please click the link in that email to complete the process
                        </p>
                        <FormManager.Input
                            formGroupClassName="mb-3"
                            name="newEmailAddress"
                            label="New Email Address"
                            length={100}
                            description="Input your New Email Address here"
                            autoComplete="new-password"
                        />
                        <FormManager.Input
                            formGroupClassName="mb-3"
                            as={PasswordField}
                            name="password"
                            label="Password"
                            autoComplete="new-password"
                            description="Input your Password here"
                        />
                    </>
                ),
            }).then((event) => {
                if (event.isSubmit) {
                    const FORM_DATA = event.json();

                    const payload = {
                        "username": user?.emailAddress,
                        "password": FORM_DATA.password,
                        "newEmailAddress": FORM_DATA.newEmailAddress,
                        "applicationAreaID": 1,
                      }

                    const toast = Toaster(`Requesting to Change Email Address`);

                    requestChangeEmailAddress
                        .call({ data: payload })
                        .then((response) => {
                            if (response.data.success) {
                                event.closeModal()
                                toast.success(response?.data?.message);
                                
                                setStates(prev => ({...prev, 
                                    currentEmail: user?.emailAddress, 
                                    requestedChangeEmail: true
                                }))
                            } else {
                                toast.fail(response?.data?.message || branding.messages.fail);
                                event.setError("")
                                FormManager.setBackendValidation(
                                    event,
                                    response.data.errors,
                                    {
                                        NewEmailAddress: "newEmailAddress",
                                    }
                                )
                                recursivePromptForm();
                            }
                        })
                        .catch((error) => {
                            recursivePromptForm();
                            const errorMessage = error?.response?.data?.message || branding.messages.error
                            toast.error(errorMessage);
                            event.setError(errorMessage);
                        })
                        .finally(() => {
                            event.removeLoading();
                        })
                }
            })
        }
        recursivePromptForm();
    }

    return (
        <Button
            id="change-email-address"
            type="button"
            className="mr-2 mb-3"
            onClick={handleChangeEmailAddress}
        >
            <Icon name="alt-penil" /> Change Email Address
        </Button>
    )
}

export default ChangeEmailButton