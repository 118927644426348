import branding from "@config/branding";
import { useScreenTitle } from "@whitecobalt/tungsten/hooks/useScreenTitle";
import React from "react";
import {toAbsoluteUrl} from "../../_metronic/_helpers";

interface ErrorPageProps {
	statusCode?: number;
	statusDescription?: string;
}

export const ErrorPage: React.FunctionComponent<ErrorPageProps> = ({statusCode = 404, statusDescription}) => {
	useScreenTitle(`${statusCode} ${statusDescription} - ${branding.brandName}`)
	return (
		<div className="d-flex flex-column flex-root">
			<div
				className="d-flex flex-row-fluid flex-column bgi-size-cover bgi-position-center bgi-no-repeat p-10 p-sm-30"
				style={{
				backgroundImage: `url(${toAbsoluteUrl("/media/error/bg1.jpg")})`
			}}>
				<h1
					className="font-size-sm-100 font-weight-boldest text-dark-75 mt-15"
					style={{ fontSize: "150px" }}>
					{statusCode}
				</h1>
				<p className="font-size-h3 font-weight-light">
					OOPS! Something went wrong here
				</p>
			</div>
		</div>
  );
}

export const NoPermissionPage: React.FunctionComponent = () => {
  	useScreenTitle(`${branding.brandName} - 404 Not Found`)
	return (
		<div className="d-flex flex-column flex-root">
			<div
				className="d-flex flex-row-fluid flex-column bgi-size-cover bgi-position-center bgi-no-repeat p-10 p-sm-30"
				style={{
				backgroundImage: `url(${toAbsoluteUrl("/media/error/bg1.jpg")})`
			}}>
				<h5
					className="font-size-sm-100 font-weight-boldest text-dark-75 mt-15 text-center"
					style={{ fontSize: "50px" }}
				>
					You do not have the required permission to access this page.
				</h5>
				<p className="font-size-h3 font-weight-light text-center">
					Please contact your Administrator for assistance.
				</p>
			</div>
		</div>
	);
}