import { gql } from "@apollo/client";

export type TimeLogPropType = {
    ID: number;
    Description: string;
    StartDateTime: string;
    EndDateTime: string;
    Hours: number;
    Locked: boolean;
    UserID: number;
    MetaData1: number;
    MetaData2: number;
    MetaData3: boolean;
    MetaData4: string;
}

export const TimeLogsGridQuery = gql`
  query(
    $skip: Int,
    $take: Int,
    $order: [TimeLogSortInput!],
    $where: TimeLogFilterInput
  ) {
    grid: TimeLogs(
      skip: $skip,
      take: $take,
      order: $order,
      where: $where
    ) {
      totalCount
      items {
        ID
        Description
        StartDateTime
        EndDateTime
        Hours
        Locked
        UserID
        MetaData1
        MetaData2
        MetaData3
        MetaData4
      }
    }
  }
`;