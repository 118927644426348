import { useRevokeImpersonation } from '@hooks/useImpersonation'
import Icon from '@whitecobalt/tungsten/esm/components/Icon'
import React from 'react'


const ImpersonationMode: React.FunctionComponent = () => {
    const handleRevokeImpersonation = useRevokeImpersonation()
    return (
        <button
            className="btn btn-secondary btn-bold"
            onClick={handleRevokeImpersonation}
        >
           <Icon name="user-secret"/> Sign out
        </button>
    )
}

export default ImpersonationMode
