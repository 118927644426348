import { gql } from "@apollo/client";

export type DashboardListGridPropType = {
    ID: number;
    Name: string;
    MaxItems: number;
    LinkURL: string;
    PKField: string;
    SQL: string;
    DefaultSort: string;
    GroupBy: string;
    DisplayAsList: boolean;
    DashboardID: number;
    Dashboard: {
      Value?: {
        DashboardGroupID: number;
        DashboardTypeID: number;
      }
    }
    DashboardListGridColumns: {
        ID: number;
        Description: string;
        SQLColumnName: string;
        ListOrder: number;
        DataType: number;
        LinkURL: string;
        LinkField: string;
        TotalColumn: number;
        Bold: boolean;
        Italic: boolean;
        RAG: boolean;
        CurrencySymbol: string;
        RedStart: number;
        RedStop: number;
        AmberStart: number;
        AmberStop: number;
        GreenStart: number;
        GreenStop: number;
        ToolTip: string;
    }[];
}

export const DashboardListGridsGridQuery = gql`
query(
    $skip: Int,
    $take: Int,
    $order: [DashboardListGridSortInput!],
    $where: DashboardListGridFilterInput
  ) {
    grid: DashboardListGrids(
        skip: $skip,
        take: $take,
        order: $order,
        where: $where
    ) {
        totalCount
        items {
            ID
            Name
            MaxItems
            LinkURL
            PKField
            SQL
            DefaultSort
            GroupBy
            DashboardID
            Dashboard {
              Value {
                DashboardTypeID
                DashboardGroup {
                  Value {
                    Description
                  }
                }
              }
            }
        }
    }
}`;

export const DashboardListGridsDisplayQuery = gql`
query(
  $skip: Int,
  $take: Int,
  $order: [DashboardListGridSortInput!],
  $where: DashboardListGridFilterInput
) {
  display: DashboardListGrids(
      skip: $skip,
      take: $take,
      order: $order,
      where: $where
  ) {
      totalCount
      items {
        ID
        Name
        MaxItems
        LinkURL
        PKField
        DashboardID
        Dashboard {
          Value {
            DashboardGroupID
            DashboardTypeID
          }
        }
        DashboardListGridColumns {
          ID
          Description
          SQLColumnName
          ListOrder
          DataType
          LinkURL
          LinkField
          TotalColumn
          Bold
          Italic
          RAG
          CurrencySymbol
          RedStart
          RedStop
          AmberStart
          AmberStop
          GreenStart
          GreenStop
          ToolTip
        }
      }
  }
}
`;

export const DashboardListGridScreenQuery = gql`
query(
    $id: Int!
  ) {
    screen: DashboardListGrid(
      iD: $id
    ) {
        ID
        Name
        MaxItems
        LinkURL
        PKField
        SQL
        DefaultSort
        GroupBy
        DashboardID
        Dashboard {
          Value {
            DashboardTypeID
            DashboardGroupID
          }
        }
        DashboardListGridColumns {
          ID
          Description
          SQLColumnName
          ListOrder
          DataType
          LinkURL
          LinkField
          TotalColumn
          Bold
          Italic
          RAG
          CurrencySymbol
          RedStart
          RedStop
          AmberStart
          AmberStop
          GreenStart
          GreenStop
          ToolTip
        }
    }
}`

export const DashboardListGridFormattingOptionsDropdownQuery = gql`
  query {
    dropdown: DashboardListGridFormattingOptions {
      value: DataType
      label: Description
    }
  }
`;
