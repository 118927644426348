export default {
    logo: {
        auth: '/media/logos/logo-whitecobalt.svg',
        splash: '/media/logos/logo-whitecobalt-inverse.png',
        navbar: '/media/logos/logo-whitecobalt.svg'
    },
    background: '/media/bg/sc-home1-bg.png',
    brandName: 'Odin',
    brandSite: '#',
    brandingFooter: false,
    allowOrganisationSelection: true,
    allowImpersonation: true,
    timeTracking: true,
    dateFormatDayLightSaving: false,
    defaultMasterTemplateID: 1,
    landingPath: '/dashboard',
    color: {
        primary: '#7E8299',
        secondary: '#3B4F64',
        loginSide: '#7E8299'
    },
    applicationAreaID: 1,
    services: {
        endpoint: process.env.REACT_APP_DATA_API_URL!,
        gql: process.env.REACT_APP_GRAPH_API_URL!,
        env: process.env.REACT_APP_ENV!,
        onesignal: process.env.REACT_APP_ONE_SIGNAL_ID!,
        gqlBatchRequest: false,
        gqlBatchMax: 5,
        gqlBatchInterval: 20,
        gqlBatchDebounce: false
    },
    token: {
        name: 'odinADTK',
        expireday: 1, // 1 day
        domain: '',
        expireMessage: 'To protect your data, you have been logged out. Please login again to continue.'
    },
    notification: {
        soundEffectEnable: true,
        pathSoundEffect: '/media/audio/mixkit-attention-bell-ding.wav'
    },
    toaster: {
        soundEffectEnable: false,
        pathSoundEffectIn: '/media/audio/mixkit-message-pop-alert.mp3',
        pathSoundEffectOut: '/media/audio/mixkit-long-pop.wav'
    },
    messages: {
        simultaneousPreventionMessage: "You have been logged out because your User Account has logged in elsewhere.",
        error: process.env.REACT_APP_ENV === 'production' ? 'Something went wrong.' : 'There is an issue with the endpoint.',
        fail: 'There are errors with your entry. Please check and try again.'
    }, 
    menuIcons: {
    },
    menuPopups: {
        'Upload Contact': 'menu.popup.company.standards'
    },
    filterGQLError: (count: number, error: any, operation: any) => {
        return false
    }
}