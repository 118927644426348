import React, { useEffect, useRef } from 'react'
import { useFormSetMethods, useGetValue } from 'formydable'
import FormManager from '@whitecobalt/tungsten/esm/components/FormManager';
import SelectDropdown, { SelectDropdownGraphReference } from '@whitecobalt/tungsten/esm/components/Fields/SelectDropdown';
import { TasksDropdownQuery } from '@services/query/DropdownQueries';
import { useSession } from '@whitecobalt/tungsten/esm/components/Session';
import { useWorldTrigger } from '@whitecobalt/tungsten/esm/common/hooks/useWorldTrigger';

export interface TaskFieldRef {
    start: () => void;
    end: () => number;
}

interface TaskFieldProps {
    id?: string;
    label?: string;
    stripped: boolean;
    loading: boolean;
    value: any;
}

const TaskField: React.FunctionComponent<TaskFieldProps> = ({ id, label, stripped, loading, value }) => {
    const ref = useRef<SelectDropdownGraphReference>(null)
    const [{user}] = useSession()
    const { setFieldValue } = useFormSetMethods()
    const projectID = useGetValue('metaData1') || 0
    const taskID = useGetValue('metaData2') || 0
    const description = useGetValue('description')

    useEffect(() => {
        if(description === "" && taskID) {
            setFieldValue('description', ref.current?.optionsByValue[taskID]?.label?.slice(0, 100))
        }
    }, [taskID])

    useWorldTrigger('time.tracker.task.reload', () => {
        ref.current?.refetch()
    }, [projectID, user?.id])

    return (
        <FormManager.Input 
            ref={ref}
            as={SelectDropdown.Graph}
            gql={TasksDropdownQuery}
            variables={{
                devUserID: taskID ? null : user?.id,
                projectID,
                where: {
                    ID: taskID ? {
                        equals: taskID,
                    } : undefined,
                    TaskStatusID: {
                        // in: [4,5,6]
                    }
                }
            }}
            manageOptions={(each: any) => ({
                value: each.value,
                label: `Task ${each.value}: ${each.label}`
            })}
            fetchPolicy="no-cache"
            id={id}
            label={label}
            name="metaData2" 
            placeholder={!label ? "Task" : "" }
            stripped={stripped}
            value={value}
            disabled={loading || !projectID} />
    )
}


export default TaskField