import gql from "graphql-tag";


export type PermissionPresetPropType = {
    ID: number;
    Title: string;
    PermissionIDs: number[];
    Permissions: {
        ID: number;
        PermissionGroupID: number;
    }
}


export const PermissionPresetsGridQuery = gql`
query(
  $skip: Int,
  $take: Int,
    $order: [PermissionPresetSortInput!],
    $where: PermissionPresetFilterInput
  ) {
    grid: PermissionPresets(
      skip: $skip,
      take: $take,
      order: $order,
      where: $where
    ) {
      totalCount
      items {
        ID
        Title
      }
    }
  }
`;

export const PermissionPresetScreenQuery = gql`
query(
    $id: Int!
  ) {
    screen: PermissionPreset(
      iD: $id
    ) {
        ID
        Title
        PermissionIDs
        Permissions {
            ID
            PermissionGroupID
        }
      }
  }
`;

export const PermissionPresetsDropdownQuery = gql`
query(
  $skip: Int,
  $take: Int,
  $order: [PermissionPresetSortInput!],
  $where: PermissionPresetFilterInput
  ) {
    dropdown: PermissionPresets(
      skip: $skip,
      take: $take,
      order: $order,
      where: $where
    ) {
      totalCount
      items {
        value: ID
        label: Title
      }
    }
  }
`;

export type PermissionGroupPropType = {
  ID: number;
  Title: string;
  ListOrder: number;
  AccessPermissionID: number;
}

export const PermissionGroupsGridQuery = gql`
query(
  $skip: Int,
  $take: Int,
    $order: [PermissionGroupSortInput!],
    $where: PermissionGroupFilterInput
  ) {
    grid: PermissionGroups(
      skip: $skip,
      take: $take,
      order: $order,
      where: $where
    ) {
      totalCount
      items {
        ID
        Title
        ListOrder
        AccessPermissionID
      }
    }
  }
`;

export type PermissionPropType = {
  ID: number;
  Title: string;
  ToolTip: string;
  ListOrder: number;
  PermissionGroupID: number;
  PermissionGroup: {
    Value?: {
      AccessPermissionID: number;
    } | null
  }
}

export const PermissionsGridQuery = gql`
query(
  $skip: Int,
  $take: Int,
    $order: [PermissionSortInput!],
    $where: PermissionFilterInput
  ) {
    grid: Permissions(
      skip: $skip,
      take: $take,
      order: $order,
      where: $where
    ) {
      totalCount
      items {
        ID
        Title
        ListOrder
        ToolTip
        PermissionGroupID
        PermissionGroup {
          Value {
            AccessPermissionID
          }
        }
      }
    }
  }
`;