import { gql } from "@apollo/client";
export enum DashboardDataType {
  Text = 1,
  Date = 2,
  DateTime = 3,
  TimeAgo = 4,
  Number = 5,
  Money = 6,
  Hyperlink = 7,
  Percentage = 8,
  Position = 11,
  Boolean = 12,
  Time = 13,
  Email = 14,
  Raw = 15,
  FullDate = 16,
  FullDateAndTime = 17,
  Image = 18,
  Hours = 20
}

export const DashboardDataTypeOptions = Object.keys(DashboardDataType).slice(17).map(key => ({
  label: key,
  value: DashboardDataType[key as keyof typeof DashboardDataType]
}))

export enum DashboardType {
  KPI = 1,
  List = 2,
  Grid = 3
}

export type DashboardPropType = {
    ID: number;
    Description: string;
    DashboardTypeID: number;
    DashboardUser: {
      ID: number;
      ListOrder: number;
      IsHidden: boolean;
    }
}

export type DashboardUserPropType = {
  ID: number;
  DashboardID: number;
  ListOrder: number;
  IsHidden: boolean;
  Dashboard: {
    Value: {
      ID: number;
      Description: string;
      DashboardTypeID: number;
    }
  }
}

export const DashboardsQuery = gql`
query(
    $skip: Int,
    $take: Int,
    $order: [DashboardSortInput!],
    $where: DashboardFilterInput
  ) {
    display: Dashboards(
      skip: $skip,
      take: $take,
      order: $order,
      where: $where
    ) {
      items {
        ID
        Description
        DashboardTypeID
      }
    }
  }
`;

export const DashboardUsersQuery = gql`
query(
    $skip: Int,
    $take: Int,
    $order: [DashboardUserSortInput!],
    $where: DashboardUserFilterInput
  ) {
    display: DashboardUsers(
      skip: $skip,
      take: $take,
      order: $order,
      where: $where
    ) {
      items {
        ID
        DashboardID
        ListOrder
        IsHidden
      }
    }
  }
`;

export const DashboardGroupsDropdownQuery = gql`
  query {
    dropdown: DashboardGroups {
      items {
        value: ID
        label: Description
      }
    }
  }
`;