import { gql } from "@apollo/client";

export type PaymentAccountPropType = {
    ID: number;
    Description: string;
    SortCode: string;
    AccountNumber: string;
}

export const PaymentAccountsGridQuery = gql`
  query(
    $skip: Int,
    $take: Int,
    $order: [PaymentAccountSortInput!],
    $where: PaymentAccountFilterInput
  ) {
    grid: PaymentAccounts(
      skip: $skip,
      take: $take,
      order: $order,
      where: $where
    ) {
      totalCount
      items {
        ID
        Description
        SortCode
        AccountNumber
      }
    }
  }
`;

export const PaymentAccountsDropdownQuery = gql`
  query(
    $skip: Int,
    $take: Int,
    $order: [PaymentAccountSortInput!],
    $where: PaymentAccountFilterInput
  ) {
    dropdown: PaymentAccounts(
      skip: $skip,
      take: $take,
      order: $order,
      where: $where
    ) {
      totalCount
      items {
        value: ID
        label: Description
      }
    }
  }
`;